import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

import Container from 'react-bootstrap/Container'

import './styles.css'

class Timer extends Component {

  state = {
    minutes: 0,
    seconds: 0,
    intervalId: null
  }


  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.quizTime !== nextProps.quizTime) {
        this.setState({
          minutes: nextProps.quizTime - 1,
          seconds: 3,
          intervalId: setInterval(() => {
            this.setState(prevState => {
              if (prevState.seconds === 0 && prevState.minutes === 0) {
                clearInterval(prevState.intervalId)
                this.props.timeHasCome()
                return {
                  intervalId: null
                }
              } else {
                return {
                  seconds: prevState.seconds === 0 ? 59 : prevState.seconds - 1,
                  minutes: prevState.seconds === 0 ? prevState.minutes - 1 : prevState.minutes
                }
              }
            })
          }, 1000)
        })
    }
  }

  componentDidMount() {
    if (this.props.quizTime) {
      this.setState({
        minutes: this.props.quizTime - 1,
        seconds: 59,
        intervalId: setInterval(() => {
          this.setState(prevState => {
            if (prevState.seconds === 0 && prevState.minutes === 0) {
              clearInterval(prevState.intervalId)
              this.props.timeHasCome()
              return {
                intervalId: null
              }
            } else {
              return {
                seconds: prevState.seconds === 0 ? 59 : prevState.seconds - 1,
                minutes: prevState.seconds === 0 ? prevState.minutes - 1 : prevState.minutes
              }
            }
          })
        }, 1000)
      })

    }
  }

  stopTimer = () => {
    clearInterval(this.state.intervalId)
    this.setState({
      seconds: 0,
      minutes: 0,
      intervalId: null
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  render() {
    if (this.props.timeIsOver && this.state.intervalId) {
      this.stopTimer()
    }
    return (
      <div className={'timeAndFinish'} >
        <Container >
          <div className={'flexCont'} >

            <div >
              <img style={{ width: '150px' }} src="https://cdn.hcsbktest.kz/uploads/logo.png" alt="Логотип ЖилстройСберБанк" />
            </div >

            <div style={{ paddingLeft: '1.5em' }} >
              <p ><b style={{ textTransform: 'capitalize' }} >{localStorage.getItem('name')}</b >, до конца тестирования
                осталось <b style={{
                  transition: '300ms',
                  color: !this.state.intervalId || this.state.minutes < 1 ? '#f44336' : 'black'
                }} >{this.state.minutes} минут {this.state.seconds} секунд!</b ></p >
              <Button onClick={this.props.submit} disabled={!this.props.disabled} color={'primary'}
                      variant={'contained'} >Завершить</Button >
            </div >


          </div >
        </Container >

      </div >
    )
  }
}

export default Timer

import React, { Component } from "react";
import Timer from "../Timer/Timer";
import Question from "./Components/Question";
import CircularProgress from '@material-ui/core/CircularProgress';

import QuestionsLoading from "./Components/QuestionsLoading";
import Pagination from "./Components/Pagination";
import styled from "styled-components";
import swal from "sweetalert";
import helpers from "../../misc/helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const { to, fetchAsync, post } = helpers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

class Index extends Component {
  state = {
    questions: [],
    selectedAnswerIndexes: [],
    questionIndex: 0,
    timeIsOver: false,
    isSubmitting: false,
    startTime: null,
    modalOpen: false,
    allQuestionsAnswered: false
  };

  async componentDidMount() {
    const { match } = this.props;
    if (
      !localStorage.getItem("started") &&
      match.params &&
      localStorage.getItem("name")
    ) {
      // if(true){
      const { id, pid } = match.params;

      // TODO: Workaround when backend is off
      const [err, tests] = await to(
        fetchAsync(
          `${
            process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()]
          }/api/tests/get/${id}/${pid}`
        )
      );

      if (!err) {
        await this.setState({
          questions: tests.test.questions,
          startTime: new Date(),
          selectedAnswerIndexes: tests.test.questions.map(question => {
            return {
              id: question["_id"],
              selectedIndex: null,
              type: question.isGeneral ? "general" : "profession"
            };
          })
        });

        localStorage.setItem("started", true);
      }
    } else {
      localStorage.clear("started");
      localStorage.clear("passScore");
      localStorage.clear("name");
      localStorage.clear("firstTime");
      this.props.history.push("/login");
    }

    window.addEventListener("keydown", this.onSpacePress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onSpacePress);
  }


  getQuizTime = () => {
       switch(this.state.questions.length ){
         case 100:
           return 90;
         default:
           return 60
       }
  }

  updateSelectedAnswers = (questionId, index) => {
    let notAnswered = 0;
    let allQuestionsAnswered = false;

    this.setState({
      selectedAnswerIndexes: this.state.selectedAnswerIndexes.map(answer => {
        if (answer.id === questionId) {
          answer.selectedIndex = index;
        }

        if (typeof answer.selectedIndex == "object") {
          notAnswered++;
        }

        return answer;
      }),
      allQuestionsAnswered: !notAnswered
    });
  };

  updateQuestionIndex = questionIndex => {
    this.setState({
      questionIndex
    });
  };

  submit = async () => {

    if (!this.state.isSubmitting) {
      this.setState({
        isSubmitting: true
      })
      const { id, pid } = this.props.match.params;

      this.setState({
        timeIsOver: true
      });

      const payload = {
        name: localStorage.getItem("name"),
        id,
        pid,
        answers: this.state.selectedAnswerIndexes,
        startTime: this.state.startTime,
        endTime: new Date(),
        passScore: localStorage.getItem("passScore")
      };

      const [err, response] = await to(
        post(
          process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
          "/api/tests/submit",
          payload
        )
      );

      if (!err) {
        localStorage.clear("name");
        localStorage.clear("firstTime");
        localStorage.clear("started");
        localStorage.clear("passScore");

        this.props.history.push("/results/" + response.resultID);
      } else {
        swal("Ошибка!", JSON.stringify(err), "error");
        this.setState({
          isSubmitting: false
        })
      }


    }
  };

  toggleModal = status => {
    this.setState({
      modalOpen: status
    });
  };

  render() {
    const hasAnsweredAtLeastOne =
      this.state.selectedAnswerIndexes.filter(
        v => typeof v.selectedIndex == "object"
      ).length === this.state.selectedAnswerIndexes.length;
    const hasAnsweredAll =
      this.state.selectedAnswerIndexes.filter(
        v => typeof v.selectedIndex == "object"
      ).length === 0;
    return (
      <Container>
        <Timer
          quizTime={this.getQuizTime()}
          submit={() => this.toggleModal(true)}
          timeHasCome={() => this.setState({ timeIsOver: true})}
          timeIsOver={this.state.timeIsOver}
          disabled={
            this.state.isSubmitting ||
            this.state.timeIsOver ||
            this.state.allQuestionsAnswered
          }
        />

        {this.state.questions.length ? (
          <Question
            timeIsOver={this.state.timeIsOver}
            questions={this.state.questions}
            updateAnswerIndex={this.updateSelectedAnswers}
            selectedAnswerIndexes={this.state.selectedAnswerIndexes}
            questionIndex={this.state.questionIndex}
          />
        ) : (
          <QuestionsLoading />
        )}

        <Pagination
          updateQuestionIndex={this.updateQuestionIndex}
          questionIndex={this.state.questionIndex}
          totalQuestions={this.state.questions.length}
        />

        <Dialog
          open={this.state.modalOpen}
          onClose={() => this.toggleModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Вы уверены, что хотите завершить тест?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.modalOpen && !hasAnsweredAll ? (
                hasAnsweredAtLeastOne ? (
                  "Вы не ответили" + " ни на один" + " вопрос"
                ) : (
                  <span>
                    Вы не ответили на след. вопросы:{" "}
                    <span style={{ wordBreak: "break-all" }}>
                      {this.state.selectedAnswerIndexes
                        .reduce(function(accumulator, currentValue, index) {
                          if (typeof currentValue.selectedIndex === "object") {
                            accumulator.push(index + 1);
                          }
                          return accumulator;
                        }, [])
                        .join(",")}
                    </span>
                  </span>
                )
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.toggleModal(false)} color="primary">
              Отмена
            </Button>
            <Button
              disabled={this.state.isSubmitting || (!this.state.timeIsOver && !hasAnsweredAll)}
              onClick={() => this.submit()}
              color="primary"
              autoFocus
            >
              {this.state.isSubmitting ? <CircularProgress style={{width: '20px', height: '20px'}} /> : 'Завершить' }
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default Index;

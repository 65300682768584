import React, {Component} from 'react';
import PropTypes from 'prop-types'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from './styles.module.scss'

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    flex-grow: 10;
    display: flex;
    width: 100%;
`


class Question extends Component {

    state = {
        chosenAnswer: null
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            chosenAnswer: null
        })
    }

    render() {
        return (
            <Wrapper>
                <Container>
                    <Content>
                        <Row className={styles.question}>
                            <Col xs={{span: 12}}>
                                <Chip style={{marginBottom: '1em'}}
                                      label={this.props.questions[this.props.questionIndex].isGeneral ? "Общий" : "По профессии"}
                                      color={this.props.questions[this.props.questionIndex].isGeneral ? "primary" : "secondary"}/>
                                <Card className={styles.loginCard}>
                                    <h3>{this.props.questions[this.props.questionIndex].name}</h3>
                                </Card>
                            </Col>
                        </Row>


                        <Row className={styles.answers}>
                            {Object.keys(this.props.questions[this.props.questionIndex].answers).map((answer, i) => {
                                return <Col xs={12} md={4} key={i}>
                                    <Card
                                        onClick={() => !this.props.timeIsOver && this.props.updateAnswerIndex(this.props.questions[this.props.questionIndex]['_id'], i)}
                                        className={[styles.card, this.props.timeIsOver ? styles.timeIsOver : '', i === this.props.selectedAnswerIndexes[this.props.questionIndex].selectedIndex ? styles.active : ''].join(' ')}>
                                        <PerfectScrollbar style={{maxHeight: '200px'}}>
                                            <div className={styles.answerContainer}>
                                                {this.props.questions[this.props.questionIndex].answers[answer]}
                                            </div>
                                        </PerfectScrollbar>
                                    </Card>
                                </Col>
                            })}
                        </Row>

                    </Content>
                </Container>
            </Wrapper>
        );
    }
}

Question.propTypes = {
    questions: PropTypes.array.isRequired,
    updateAnswerIndex: PropTypes.func.isRequired,
    questionIndex: PropTypes.number.isRequired,
    selectedAnswerIndexes: PropTypes.array.isRequired
}

export default Question;
import React from "react";

const ReadBeforeStart = () => {
  return (
    <div>
      <p>
        В рамках «Центр компетенций ИТ Блока», основными задачами которого
        являются: создание внутреннего центра взращивания и развития персонала,
        обеспечение преемственности, внедрение прозрачной оценки компетенций
        работников ИТ блока.
      </p>

      <p>
        Одним из этапов аттестации является {" "}
        <strong>тестирование для определения Hard Skills.</strong>
      </p>

      <p>Язык тестирования - русский.</p>

      <p>Продолжительность тестирования - 60 минут.</p>

      <p>
        На прохождение теста дается <strong>1 попытка</strong> (в тесте
        установлен таймер времени).
      </p>

      <strong>Удачного прохождения теста!</strong>
    </div>
  );
};

export default ReadBeforeStart;

import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import Skeleton from 'react-loading-skeleton';

import styled from 'styled-components'

import helpers from '../../misc/helpers'

const {to, fetchAsync} = helpers

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const CardContainer = styled.div`
    padding: 1.5em 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

class Index extends Component {

    state = {
        result: null
    }

    async componentDidMount() {
        const {match} = this.props

        if (match.params) {
            const {id} = match.params;

            const [err, tests] = await to(
                fetchAsync(`${process.env['REACT_APP_HOST_' + process.env.NODE_ENV.toUpperCase()]}/api/tests/results/get/${id}`)
            );

            if (!err) {
                if (!tests.msg) {
                    this.setState({
                        result: tests.result
                    })
                } else {
                    this.props.history.push('/login')
                }

            } else {
                this.props.history.push('/login')
            }

        } else {
            this.props.history.push('/login')
        }

    }

    render() {
        let takenTime, takenMinutes, takenSeconds;



        return (
            <Wrapper>
                <Container>
                    <Row>

                        <Col xs={12} md={{span: 8, offset: 2}}>
                            <Card>
                                <CardContainer>

                                    <h3 style={{textAlign: 'center'}}>Результаты тестирования</h3>
                                    {this.state.result ? (<section>

                                        <ol style={{listStyle: 'none'}}>
                                            <li style={{textTransform: 'capitalize'}}><b>ФИО</b> - {this.state.result.name}</li>
                                            <li><b>Должность</b> - {this.state.result.profession.name}</li>
                                            <li><b>Департамент</b> - {this.state.result.department.name}</li>
                                            <br/>
                                            <li>
                                                <b>Дата</b> - {new Date(this.state.result.startTime).toLocaleDateString('ru')}
                                            </li>
                                            <li><b>Время
                                                начала</b> - {new Date(this.state.result.startTime).toLocaleTimeString('ru')}
                                            </li>
                                            <li><b>Время
                                                окончания </b> - {new Date(this.state.result.endTime).toLocaleTimeString('ru')}
                                            </li>
                                            <li>
                                                <b>Продолжительность</b> - {this.state.result.takenTime}
                                            </li>
                                            <br/>
                                            <li><b>Неотвеченных вопросов
                                                - </b> {this.state.result.notAnsweredQuestions}
                                            </li>
                                            <li><b>Правильных ответов
                                                - </b> {this.state.result.totalAnswers - this.state.result.totalWrongAnswers}
                                            </li>
                                            <li><b>Неправильных ответов- </b> {this.state.result.totalWrongAnswers}</li>
                                            <li><b>Правильных ответов на вопросы общего характера
                                                - </b> {this.state.result.rightAnswers.general}</li>
                                            <li><b>Правильных ответов на профессиональные вопросы
                                                - </b> {this.state.result.rightAnswers.professional}</li>

                                            <br/>
                                            <li><b>Вы набрали</b> - {this.state.result.score}%</li>
                                            <li><b>Проходной балл</b> - {this.state.result.passScore}%</li>
                                            <li><b>Тест {this.state.result.score >= this.state.result.passScore ?
                                                <span style={{color: 'green'}}> пройден </span> :
                                                <span style={{color: 'tomato'}}> не пройден</span>}  </b></li>
                                        </ol>


                                    </section>) : <div style={{display: 'block', width: '80%'}}>
                                        <Skeleton count={12}/>
                                    </div>}
                                </CardContainer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
        );
    }
}

export default Index;

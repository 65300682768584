import React, {Component} from 'react';
import Login from './components/Login'
import Quiz from './components/Quiz'
import Results from './components/Results'
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {BrowserRouter as Router, Route,  Switch, Redirect} from "react-router-dom";

import './styles.scss'

class App extends Component {


    render() {


        return (
            <div className="App">

                <Router>
                    <Route
                        render={({location, match}) => (
                            <div>


                                <div>
                                    <TransitionGroup>

                                        <CSSTransition
                                            key={location.key}
                                            classNames="fade"
                                            timeout={1000}
                                        >
                                            <Switch>
                                                <Route path="/login" exact component={Login}/>
                                                <Redirect from={'/quiz'} exact to={'/login'} />
                                                <Redirect from={'/'} exact to={'/login'} />
                                                <Redirect from={'/results'} exact to={'/login'} />
                                                <Redirect from={'/quiz/:id'} exact to={'/login'} />
                                                <Route path="/quiz/:id/:pid" exact component={Quiz}/>
                                                <Route path="/results/:id" exact component={Results}/>
                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                                </div>
                            </div>
                        )}
                    />
                </Router>


            </div>
        );
    }
}

export default App;

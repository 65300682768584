import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "./styles.module.scss";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import styled from "styled-components";
import 'react-perfect-scrollbar/dist/css/styles.css';
import Skeleton from 'react-loading-skeleton';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    flex-grow: 10;
    display: flex;
    width: 100%;
`
const QuestionsLoading = () => {
    return (
        <Wrapper>
            <Container>
                <Content>
                    <Row className={styles.question}>
                        <Col xs={{span: 12}}>
                            <Card className={styles.loginCard}>
                                <div style={{marginBottom: '1em'}}>
                                    <Skeleton circle={true} width={50} height={50}/>
                                </div>
                                <Skeleton width={'100%'}/>
                            </Card>
                        </Col>
                    </Row>


                    <Row className={styles.answers}>
                        <Col  xs={12} md={4} >
                            <Card style={{minHeight: '100px', padding: '2em 1.5em'}}>
                                <Skeleton count={3}/>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} >
                            <Card style={{minHeight: '100px', padding: '2em 1.5em'}}>
                                <Skeleton count={3}/>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} >
                            <Card style={{minHeight: '100px', padding: '2em 1.5em'}}>
                                <Skeleton count={3}/>
                            </Card>
                        </Col>
                    </Row>

                </Content>
            </Container>
        </Wrapper>
    );
};

export default QuestionsLoading;
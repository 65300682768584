import React from "react";
import PropTypes from 'prop-types'
import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import styled from 'styled-components'

const theme = createMuiTheme();

const Wrapper = styled.div`
    display: flex;
    flex-grow: 4;
`

class PaginationContainer extends React.Component {


    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Wrapper>
                    <CssBaseline/>
                    <Pagination
                        limit={1}
                        offset={this.props.questionIndex}
                        total={this.props.totalQuestions}
                        onClick={(e, offset) => this.props.updateQuestionIndex(offset)}
                    />
                </Wrapper>
            </MuiThemeProvider>
        );
    }
}

PaginationContainer.propTypes = {
    totalQuestions: PropTypes.number.isRequired,
    questionIndex: PropTypes.number.isRequired,
    updateQuestionIndex: PropTypes.func.isRequired
}

export default PaginationContainer

import React, {useState} from 'react';
import Container from "react-bootstrap/Container";
import styles from "./styles.module.scss";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import styled from 'styled-components'
import AsyncSelect from 'react-select/lib/Async';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import helpers from '../../misc/helpers'
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import ReadBeforeStart from './ReadBeforeStart'
import IconButton from "@material-ui/core/IconButton";

const Employee = styled.ul`
  list-style: none;
  text-align: left;
  font-size: .9em;
  padding: 0 !important;
`

const {to, post} = helpers

const stylesObj = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        margin: '0 auto',
        backgroundColor: theme.palette.background.paper,
    },
    chip: {
        marginRight: theme.spacing.unit,
    },
    section1: {
        margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
    section2: {
        margin: theme.spacing.unit * 2,
    },
    section3: {
        margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 250,
        textTransform: 'capitalize'
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },

    noOptionsMessage: {
        padding: `${16}px ${16 * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: 16,
        textTransform: 'capitalize',
        left: 0,
        right: 0,
    },
    divider: {
        height: 16 * 2,
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            style={{textTransform: 'capitalize'}}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};


const loadOptions = async (inputValue, cb) => {

    let [err, employees] = await to(post(process.env['REACT_APP_HOST_' + process.env.NODE_ENV.toUpperCase()] + '/api/employee/get/name', {
        name: inputValue
    }))

    if (!err) {
        cb(employees.employees.map(suggestion => {
                return {
                    label: suggestion.trim(),
                    value: suggestion
                }
            })
        )
    }

}
const findUser = async (name, addEmployee, setIDs, toggleSearch) => {

    toggleSearch(true)

    if (name) {
        let [err, employee] = await to(post(process.env['REACT_APP_HOST_' + process.env.NODE_ENV.toUpperCase()] + '/api/employee/find', {
            name: name.label.toLowerCase()
        }))


        if (!err) {
            addEmployee(employee.employee)
            setIDs(employee.ids)
            toggleSearch(false)
            return employee
        } else {
            toggleSearch(false)
            return false
        }
    }

    toggleSearch(false)

}

const startTest = (name, {did, pid}, history) => {

    if (name && did && pid && history) {
        localStorage.setItem('name', name.label);
        history.push(`/quiz/${did}/${pid}`)
    }
}

const checkCode = async code => {
    let [err, status] = await to(post(process.env['REACT_APP_HOST_' + process.env.NODE_ENV.toUpperCase()] + '/api/code/check', {
        value: code
    }))
    return !err && status.passed;
}

const FindUserForm = ({classes, history}) => {

    const classes2 = useStyles();
    const [single, setSingle] = React.useState(null);
    const [modalOpen, toggleModal] = React.useState(false)
    const [employee, addEmployee] = useState('')
    const [securityCode, updateSC] = useState('')
    const [allowTest, updateAllow] = useState(false)
    const [searchingEmployee, toggleSearch] = useState(false)
    const [ids, setIDs] = useState(null)
    const [snack, toggleSnack] = useState(false)


    localStorage.removeItem('started')

    const selectStyles = {
        input: base => ({
            ...base,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    function handleChangeSingle(value, addEmployee, setIDs, toggleSearch) {
        if (!value) {
            addEmployee(null)
        }
        findUser(value, addEmployee, setIDs, toggleSearch)
        setSingle(value);
    }

    return (
        <Container className={styles.container}>

            <div className={classes.root}>
                <div className={classes.section1}>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography style={{textAlign: 'center'}} gutterBottom variant="h4">
                                Тестирование    
                            </Typography>
                        </Grid>

                    </Grid>
                    <Typography style={{textAlign: 'center'}} color="textSecondary">
                        Для того, чтобы начать тестирование, укажите ваше имя
                    </Typography>
                </div>
                <Divider variant="middle"/>
                <div className={classes.section2}>

                    <FormControl style={{width: '100%', marginTop: '1em', marginBottom: '1.5em'}}>

                        <AsyncSelect
                            classes={classes2}
                            label={'ФИО'}
                            noOptionsMessage={() => 'ФИО не найдено'}
                            loadingMessage={() => 'Поиск...'}
                            styles={selectStyles}
                            components={components}
                            value={single}
                            loadOptions={loadOptions}
                            placeholder={"Введите ФИО"}
                            onChange={(e) => handleChangeSingle(e, addEmployee, setIDs, toggleSearch)}
                            isClearable
                        />

                    </FormControl>

                    <Employee>
                        {employee ? (
                            <React.Fragment>
                                <li style={{textTransform: 'capitalize'}}><strong> Имя </strong> : {employee.name}</li>
                                <li><strong> Департамент </strong> : {employee.department}</li>
                                <li><strong> Профессия </strong> : {employee.profession}</li>
                            </React.Fragment>
                        ) : null}

                        {
                            searchingEmployee ? <CircularProgress style={{display: 'block', margin: '0 auto'}}/> : null
                        }
                    </Employee>

                    {
                        employee ? (
                            <React.Fragment>
                                <FormControl style={{width: '100%', marginTop: '1em', marginBottom: '1.5em'}}>
                                    <TextField value={securityCode}
                                               onChange={e => updateSC(e.target.value)} name={'securityCode'}
                                               placeholder={'Код'}/>
                                </FormControl>
                                <Button onClick={ async () => {
                                    const [err,passed] = await to(checkCode(securityCode))
                                    if(!err && passed){
                                        updateSC('')
                                        updateAllow(true)
                                    } else {
                                        toggleSnack(true)
                                    }
                                }} type={'button'} variant={'outlined'} color={'secondary'} >   Подтвердить </Button>
                            </React.Fragment>

                        ) : null
                    }

                </div>
                <div className={classes.section3}>
                    <Button onClick={() => toggleModal(true)} disabled={!allowTest}
                            variant="contained" color="primary" fullWidth>
                        Начать
                    </Button>
                </div>
            </div>

            <Dialog
                open={modalOpen}
                onClose={() => toggleModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title">{"Тестирование в рамках «Центр компетенций ИТ Блока»" }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ReadBeforeStart/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleModal(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={() => {
                        startTest(single, ids, history)
                        localStorage.setItem('passScore', employee.passScore)
                    }} color="primary" autoFocus>
                        Начать
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => toggleSnack(false)}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Неверный код</span>}

            />
        </Container>
    );
};

export default withStyles(stylesObj)(FindUserForm);

import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import {MuiThemeProvider} from '@material-ui/core/styles';
import theme from '../../misc/theme'
import FindUser from "./FindUserForm";
import styled from "styled-components";
import styles from './styles.module.scss'
import helpers from '../../misc/helpers'


const {to, fetchAsync} = helpers

const CenterBlock = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
`


class Index extends Component {


    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <CenterBlock>
                    <Container>
                        <Row style={{alignItems: 'center'}}>
                            <Col xs={12} md={{span: 6, offset: 3}}>
                                <img src={"https://cdn.hcsbktest.kz/uploads/logo.png"} style={{
                                    width: '200px',
                                    marginBottom: '2em',
                                    display: 'block',
                                    margin: '0 auto 2em'
                                }} alt={'Лого'}/>
                                <Card className={styles.loginCard}>
                                    <FindUser  history={this.props.history}/>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </CenterBlock>
            </MuiThemeProvider>
        );
    }
}

export default Index;